import { ref, onMounted, watchEffect, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import useVuelidate from '@vuelidate/core'
import userService from '@/services/auth.service'
import { getCookie, setHeaders } from '@/utils'
import { omit } from 'lodash'
import { PATH } from '@/constants'
import { useStore } from 'vuex'
import { AUTH_STORE, USER_STORE } from '@/store/constants'
import axios from 'axios'

export const useLogin = (rules, formData) => {
  const route = useRoute()
  const router = useRouter()
  const showRecaptcha = ref<boolean>(true)
  const rememberMe = ref<boolean>(true)
  const errorLogin = ref<boolean>(false)
  const checkCaptcha = ref(false)
  const siteKey = process?.env?.VUE_APP_SITE_KEY
  const disableBtn = ref(true)
  // 2022/12/02 TienNV bug UAT ph2 #5083 (Ph2-R20221222)
  const isCheckRecaptcha = `${process.env.VUE_APP_CHECK_RECAPTCHA}`
  const v$ = useVuelidate(rules, formData)
  const checkSubmit = ref(false)
  const unSubmitManyTime = ref(false)
  const store = useStore()

  const emailInvite = computed(() => store.getters[USER_STORE.GETTERS.GET_MAIL_INVITE])

  const recaptchaVerified = (response: any): void => {
    checkCaptcha.value = true
    formData.value['g-recaptcha-response-data'] = response
  }

  const resetCaptCha = (reCaptChaRef) => {
    // 2022/09/14 TienNV bug UAT ph2 #4435
    checkCaptcha.value = isCheckRecaptcha === 'false'
    formData.value['g-recaptcha-response-data'] = ''
    reCaptChaRef.value.reset()
  }

  const submit = async (reCaptChaRef) => {
    checkSubmit.value = true
    unSubmitManyTime.value = true
    v$.value.$touch()
    if (v$.value.$invalid) return

    if (checkCaptcha.value) {
      try {
        const data: any = route.path.split('/')[1] === 'alumni' ? await userService.alumnusLoginApi(formData.value) : await userService.communityLoginApi(formData.value)
        if (data.headers?.expiry) {
          axios.defaults.headers['X-CSRF-TOKEN'] = data.headers['access-token']

          const { expiry } = data.headers
          // 2022/10/21 CW-TrungNT Fix UAT#4684: CA80, SA80 (Ph2-R20221021)
          let expires = `${expiry ? new Date(parseInt(expiry) * 1000).toUTCString() : new Date()['toGMTString']()}`

          const basePath = PATH[`${route.path.split('/')[1]}`]
          const dataHeader = { ...data.headers, 'request-path': basePath.path }

          document.cookie = `${basePath.pathName}=${basePath.path}; expires=${expires}; path=/`
          if (route.path !== '/community/users/login') {
            document.cookie = `invited_code=${route.params?.key ?? ''}; expires=${expires}; path=/`
          }
          document.cookie = `${basePath.header}=${JSON.stringify(omit(dataHeader, ['cache-control', 'content-type']))}; expires=${expires}; secure; path=/`
          setHeaders(dataHeader)
          errorLogin.value = false
          unSubmitManyTime.value = false
          redirectPage()
        } else if (!data?.data?.success && data?.message !== 'Network Error' && data?.message !== 'timeout') {
          resetCaptCha(reCaptChaRef)
          errorLogin.value = true
        }
      } catch (error) {
        console.log(error)
        errorLogin.value = true
        resetCaptCha(reCaptChaRef)
        unSubmitManyTime.value = true
      }
    }
  }

  const redirectPage = (): void => {
    const defaultUrl = `/${route.path.split('/')[1]}/lobby`

    if (route.query.redirect === '/community/join_users' && formData.value?.email !== emailInvite.value) {
      router.push({ path: defaultUrl })
      return
    }
    let url = route.query.redirect ? '' + route.query.redirect : defaultUrl
    router.push({ path: url })
  }

  onMounted(async () => {
    const path = PATH[`${route.path.split('/')[1]}`]
    if (getCookie(path?.header) && getCookie(path.pathName)) {
      redirectPage()
      return
    }
  })
  onMounted(() => {
    // 2022/12/02 TienNV bug UAT ph2 #5083 (Ph2-R20221222)
    if (isCheckRecaptcha == 'false') {
      checkCaptcha.value = true
    }
  })
  watchEffect(() => {
    if (!v$.value.$invalid && checkCaptcha.value && !unSubmitManyTime.value) {
      disableBtn.value = false
    } else disableBtn.value = !errorLogin.value
  })
  return { showRecaptcha, recaptchaVerified, siteKey, rememberMe, errorLogin, checkCaptcha, v$, checkSubmit, submit, disableBtn }
}
